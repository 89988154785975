/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Un ", React.createElement(_components.a, {
    href: "http://www.porticoluna.org/static/archivadoe5f2.html?archivo=amezquita4001.htm&autor=Irma+Am%E9zquita&titulo=Ciencia+Ficci%F3n+Dura%3A+Indispensable+para+el+g%E9nero&categoriaf=Ensayo&categoriat=&apartado=Escritos&traductor=&temcar=escritos/"
  }, "interesante ensayo sobre la Hard Science Fiction"), ", lo que se puede traducir  como Ciencia Ficción Dura.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
